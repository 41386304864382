var PeopleMedia = PeopleMedia || {};
PeopleMedia.Net = (function () {
    "use strict";
    var Oid = "PeopleMedia.Net";

    var handleException = function () {
        try {
            if (typeof (PeopleMedia.CP.handleException) === "function") {
                PeopleMedia.CP.handleException.apply(null, arguments);
            }
        } catch (ex) {
        }
    };

    var createCookie = function(name, value, days, serialize) {
        var cookieDomain = null;
        try {
            var expires = "";
            var cookieValue;
            cookieDomain = (getDomainName()).toLowerCase();
            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                expires = "; expires=" + date.toGMTString();
            }
            cookieValue = (arguments.length > 3 && serialize) ? serializeKeyValues(value) : value;
            document.cookie = name + "=" + cookieValue + expires + "; path=/; domain=" + cookieDomain + ";secure";
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "createCookie", cookieDomain: cookieDomain });
        }
    };

    var getDomainName = function(hostname) {
        var host;
        try {
            host = hostname || window.location.hostname;
            var arr = host.split('.');
            var arrLen = arr.length;
            if (arrLen === 3) {
                return arr[1] + '.' + arr[2];
            }
            if (arrLen === 4) {
                var ip = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/g;
                if (ip.test(host)) {
                    return host;
                }
            }
            if (arrLen > 3) {
                return arr[arrLen - 2] + '.' + arr[arrLen - 1];
            }
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "getDomainName" });
        }
        return host;
    };

    var readCookie = function(name, raw) {
        try {
            var nameEQ = name + "=";
            var ca = document.cookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) === ' ') {
                    c = c.substring(1, c.length);
                }
                if (c.indexOf(nameEQ) === 0) {
                    var val = c.substring(nameEQ.length, c.length);
                    if (!raw && (val.indexOf('&') >= 0 || val.indexOf('=') >= 0)) {
                        return parseKeyValueString(val);
                    } else {
                        return val;
                    }
                }
            }
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "readCookie", name: name });
        }
        return null;
    };

    var eraseCookie = function(name) {
        try {
            createCookie(name, "", -1);
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "eraseCookie", name: name });
        }
    };

    var parseKeyValueString = function(val) {
        var hash = [];
        try {
            var pieces = val.split('&');
            for (var i = 0; i < pieces.length; i++) {
                var pair = pieces[i].split('=');
                if (pair.length > 0) {
                    var key = decodeURIComponent(pair[0]);
                    var str = decodeURIComponent(pair[1]);
                    hash[key] = str;
                }
            }
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "parseKeyValueString" });
        }
        return hash;
    };

    var serializeKeyValues = function(hash) {
        try {
            if (typeof(hash) !== "undefined" && hash !== null) {
                var pairs = [];
                for (var key in hash) {
                    if (hash.hasOwnProperty(key)) {
                        pairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(hash[key]));
                    }
                }
                return pairs.join('&');
            }
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "serializeKeyValues" });
        }
        return null;
    };

    var getQueryString = function(queryString) {
        try {
            var qs = (typeof (queryString) === 'undefined' || queryString === null)
                ? window.location.search
                : queryString;
            return parseQueryString(qs);
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "getQueryString" });
            return [];
        }
    };

    var parseQueryString = function(queryString) {
        try {
            var keyVals = [];
            var raw;
            if (typeof (queryString) === 'undefined' || queryString === null || queryString.length < 1) {
                raw = '';
            } else if (queryString.substring(0, 1) === '?') {
                raw = queryString.substring(1);
            } else {
                raw = queryString;
            }
            var pairs = raw.split("&");
            for (var i = 0, count = pairs.length; i < count; i++) {
                var key = "";
                var val = "";
                var equalPos = pairs[i].indexOf("=");

                if (equalPos < 0) {
                    key = pairs[i];
                    val = null;
                } else {
                    if (equalPos >= 1) {
                        key = pairs[i].substring(0, equalPos);
                    }
                    if (equalPos < pairs[i].length - 1) {
                        val = pairs[i].substring(equalPos + 1, pairs[i].length);
                    }
                }
                if (key.length > 0) {
                    keyVals.push({ "key": key, "value": val });
                }
            }
            return keyVals;
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "parseQueryString" });
            return [];
        }
    };

    var createQueryString = function(keyValues, excludeKeys) {
        try {
            var newQS = "";
            var keyFound;
            var currentKeyValuePair;
            var currentKey;
            var currentKeyLower;
            var currentValue;
            if (typeof (excludeKeys) === 'undefined' || excludeKeys === null || excludeKeys.length <= 0) {
                excludeKeys = [];
            }
            if (Array.isArray(keyValues)) {
                for (var i = 0, count = keyValues.length; i < count; i++) {
                    currentKeyValuePair = keyValues[i];
                    if (typeof (currentKeyValuePair) !== 'undefined' && currentKeyValuePair !== null && currentKeyValuePair.hasOwnProperty("key") && currentKeyValuePair.hasOwnProperty("value")) {
                        currentKey = currentKeyValuePair.key;
                        currentKeyLower = (typeof (currentKey) !== 'undefined' && currentKey !== null) ? currentKey.toLowerCase() : currentKey;
                        currentValue = currentKeyValuePair.value;
                        keyFound = false;
                        for (var excludeIndex = 0, excludeCount = excludeKeys.length; excludeIndex < excludeCount; excludeIndex++) {
                            if (excludeKeys[excludeIndex] && excludeKeys[excludeIndex].toLowerCase() === currentKeyLower) {
                                keyFound = true;
                                break;
                            }
                        }
                        if (!keyFound) {
                            if (newQS.length > 0) {
                                newQS += "&";
                            }
                            newQS += currentKey + ((typeof (currentValue) !== 'undefined' && currentValue !== null) ? ("=" + currentValue) : '');
                        }
                    }
                }
            }
            if (newQS.length > 0) {
                newQS = "?" + newQS;
            }
            return newQS;
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "createQueryString" });
            return [];
        }
    };

    var getQueryStringValue = function(keyValues, key) {
        try {
            var currentKeyValuePair;
            var currentKey;
            var currentKeyLower;
            var keyLower;
            if (typeof (keyValues) !== 'undefined' && keyValues !== null && typeof (key) !== 'undefined' && key !== null) {
                if (Array.isArray(keyValues)) {
                    keyLower = key.toLowerCase();
                    for (var i = 0, count = keyValues.length; i < count; i++) {
                        currentKeyValuePair = keyValues[i];
                        if (typeof (currentKeyValuePair) !== 'undefined' && currentKeyValuePair !== null && currentKeyValuePair.hasOwnProperty("key") && currentKeyValuePair.hasOwnProperty("value")) {
                            currentKey = currentKeyValuePair.key;
                            currentKeyLower = (typeof (currentKey) !== 'undefined' && currentKey !== null) ? currentKey.toLowerCase() : currentKey;
                            if (currentKeyLower === keyLower) {
                                return currentKeyValuePair.value;
                            }
                        }
                    }
                }
            }
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "getQueryStringValue" });
        }
        return null;
    };

    var createKeyValues = function(dict) {
        try {
            var keyVals = [];
            var val;
            for (var key in dict) {
                if (dict.hasOwnProperty(key) && typeof (key) !== 'undefined' && key !== null && key.length > 0) {
                    val = dict[key];
                    keyVals.push({ "key": key, "value": val });
                }
            }
            return keyVals;
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "createKeyValues" });
        }
        return [];
    };

    var addQueryStringValue = function(url, name, val) {
        try {
            var newUrl = (typeof (url) !== 'undefined' && url !== null) ? url : '';
            var nameValue = ((typeof (name) !== 'undefined' && name !== null) ? name : '') + ((typeof (val) !== 'undefined' && val !== null) ? ("=" + val) : '');
            if (nameValue.length > 0) {
                return newUrl + ((newUrl.indexOf("?") < 0) ? '?' : '&') + nameValue;
            } else {
                return newUrl;
            }
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "addQueryStringValue" });
            return [];
        }
    };

    var getUrlWithoutQueryString = function(url) {
        try {
            if (typeof (url) === 'undefined' || url === null) {
                return '';
            }
            var qIndex = url.indexOf("?");
            if (qIndex < 0) {
                return url;
            } else {
                return url.substring(0, qIndex);
            }
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "getUrlWithoutQueryString" });
            return [];
        }
    };

    var getQueryStringFromUrl = function(url) {
        try {
            if (typeof (url) === 'undefined' || url === null) {
                return '';
            }
            var qIndex = url.indexOf("?");
            if (qIndex > 0) {
                return url.substring(qIndex + 1);
            } else {
                return '';
            }
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "getQueryStringFromUrl" });
            return [];
        }
    };

    var getQueryStringKeys = function(keyValues) {
        var result = [];
        try {
            var currentKeyValuePair;
            var currentKey;
            if (typeof (keyValues) !== 'undefined' && keyValues !== null) {
                if (Array.isArray(keyValues)) {
                    for (var i = 0, count = keyValues.length; i < count; i++) {
                        currentKeyValuePair = keyValues[i];
                        if (typeof (currentKeyValuePair) !== 'undefined' && currentKeyValuePair !== null && currentKeyValuePair.hasOwnProperty("key") && currentKeyValuePair.hasOwnProperty("value")) {
                            currentKey = currentKeyValuePair.key;
                            if (typeof (currentKey) !== 'undefined' && currentKey !== null) {
                                result.push(currentKey);
                            }
                        }
                    }
                }
            }
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "getQueryStringKeys" });
        }
        return result;
    };

    var getQueryStringKeyValue = function (key) {
        try {
            var qs = PeopleMedia.Net.getQueryString();
            return PeopleMedia.Net.getQueryStringValue(qs, key); 
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "getQueryStringKeyValue", key: key });
            return null;
        }
    };

    var persistCookies = function (response) {
        if (response.Cookies) {
            for (var key in response.Cookies) {
                if (response.Cookies.hasOwnProperty(key)) {
                    PeopleMedia.Net.createCookie(key, response.Cookies[key], 30);
                }
            }
        }
    };

    var supportsWebStorage = function() {
        try {
            window.sessionStorage.setItem('Lps.State.Test', '1');
            var readTest = window.sessionStorage.getItem('Lps.State.Test');
            window.sessionStorage.removeItem('Lps.State.Test');
            return (readTest === '1');
        } catch (e) {
            return false;
        }
    };

    var loadState = function(name) {
        var state = {};
        name = name || "Lps.State";
        if (supportsWebStorage()) {
            state = window.sessionStorage.getItem(name);
        } else {
            state = readCookie(name, true);
        }
        try {
            state = JSON.parse(state);
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "loadState", name: name });
            state = null;
        }
        return state || {};
    };

    var saveState = function(name, value) {
        try {
            name = name || "Lps.State";
            var serializedValue = JSON.stringify(value);
            if (supportsWebStorage()) {
                window.sessionStorage.setItem(name, serializedValue);
            } else {
                createCookie(name, serializedValue);
            }
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "saveState", name: name });
        }
    };

    var clearState = function(name) {
        try {
            name = name || "Lps.State";
            if (supportsWebStorage()) {
                window.sessionStorage.removeItem(name);
            } else {
                eraseCookie(name);
            }
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "clearState", name: name });
        }
    };

    return {
        createCookie: createCookie,
        getDomainName: getDomainName,
        readCookie: readCookie,
        eraseCookie: eraseCookie,
        persistCookies: persistCookies,
        getQueryString: getQueryString,
        parseQueryString: parseQueryString,
        createQueryString: createQueryString,
        getQueryStringValue: getQueryStringValue,
        addQueryStringValue: addQueryStringValue,
        getUrlWithoutQueryString: getUrlWithoutQueryString,
        getQueryStringFromUrl: getQueryStringFromUrl,
        getQueryStringKeys: getQueryStringKeys,
        getQueryStringKeyValue: getQueryStringKeyValue,
        parseKeyValueString: parseKeyValueString,
        createKeyValues: createKeyValues,
        supportsWebStorage: supportsWebStorage,
        loadState: loadState,
        saveState: saveState,
        clearState: clearState
    };
})();
